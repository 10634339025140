export enum ComponentsType {
  Project = 'Project',
  Client = 'Client',
  Employee = 'Employee',
}


export enum FiledType {
  Text = 'text',
  Text_Area= 'long_text'
}
