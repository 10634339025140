<div>
  <!-- <app-card-header
    [cardTitle]="cardTitle"
    [cardSubTitle]="''"
    [buttons]="buttons"
    [cardLabelClass]="'mb-0'"
  ></app-card-header> -->
  <!-- {{ projectId }} {{ extendFields }} {{ extendFieldsObj }} -->
  <div>
    <div>
      <ng-container *ngTemplateOutlet="extendForm"></ng-container>
    </div>
    <!-- <div
      *ngIf="projectId && extendFields && extendFieldsObj"
      class="form-group d-flex flex-wrap justify-content-end align-items-center pt-5 mt-4"
    >
      <button
        id="ContactCancel"
        type="button"
        class="btn-cancel"
        (click)="onClose()"
      >
        Cancel
      </button>
      <button
        id="Submit"
        type="submit"
        [isSubmitting]="isSubmitting"
        class="btn-save"
        (click)="save()"
      >
        Save
      </button>
    </div> -->
  </div>
  <!-- <p *ngIf="!projectId" class="mt-5 text-capitalize text-center">No Data</p> -->
</div>
<ng-template #extendForm>
  <div class="row col-12 py-5">
    <ng-container *ngFor="let item of extendFields; let index = index">
      <!-- <p>{{ item.id }}</p> -->
      <ng-container
        *ngFor="
          let filed of item?.jsonData?.extendedFieldsConfig;
          let index2 = index
        "
      >
        <ng-container *ngIf="filed?.component == componentType">
          <ng-container
            *ngFor="let filedDetails of filed?.fields; let index3 = index"
          >
            <label *ngIf="checkShowORHide(filedDetails?.name)" class="form-label" [for]="filedDetails?.name">{{
              filedDetails?.name
            }}</label>
            <input
            *ngIf="checkShowORHide(filedDetails?.name) && filedDetails.type === filedType.Text"
              type="text"
              class="form-control custom mb-2"
              required
              [value]="getValueByPartialKey(filedDetails?.name)"
              (change)="
                updateObjectByPartialKey(
                  filedDetails?.name,
                  $event.target.value
                )
              "
              (focus)="
                updateObjectByPartialKey(
                  filedDetails?.name,
                  $event.target.value
                )
              "
              [placeholder]="filedDetails?.name"
            />
            <textarea
            *ngIf="checkShowORHide(filedDetails?.name) && filedDetails.type === filedType.Text_Area"
            class="form-control custom "
            rows="5"
            cols="5"
            [value]="getValueByPartialKey(filedDetails?.name)"
            pInputTextarea
            (change)="
                updateObjectByPartialKey(
                  filedDetails?.name,
                  $event.target.value
                )
              "
              (focus)="
                updateObjectByPartialKey(
                  filedDetails?.name,
                  $event.target.value
                )
              "
               [placeholder]="filedDetails?.name"
          ></textarea>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
