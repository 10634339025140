import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdministrationService } from '@entities/administration/administration.service';
import { SflBaseComponent } from '@shared/components/sfl-base/sfl-base.component';
import { ComponentsType } from '@shared/models/component-type-enum';
import { ButtonParams } from '../../models/custom/button-params.model';
import { FiledType } from '../../models/component-type-enum';

@Component({
  selector: 'app-extended-form',
  templateUrl: './extended-form.component.html',
  styleUrls: ['./extended-form.component.scss']
})
export class ExtendedFormComponent extends SflBaseComponent implements OnInit {
  @Input() filedName= '';
  cardTitle = 'More Fields'
  extendFields: any;
  @Input() extendFieldsObj: any = {};
  filedType= FiledType;
  Component = ComponentsType;
  globalDetailId: any;
  @Input() projectId: any;
  @Input() componentType: ComponentsType
  @Output() closeSidebarEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() dataChangeEvent: EventEmitter<boolean> = new EventEmitter();
  constructor(private readonly adminService: AdministrationService, private readonly cdr: ChangeDetectorRef) { super(); }

  ngOnInit(): void {
    this.getGlobalDetailsCategory();
    this.cdr.detectChanges()
  }

  buttons: ButtonParams[] = [
    {
      btnClass: 'btn-close-icon',
      btnIcon: 'times',
      action: this.onClose.bind(this)
    }
  ];

  getGlobalDetailsCategory(): void {
    this.subscriptionManager.add(
      this.adminService.getExtendedFields('ManageExtendedFiled').subscribe((res) => {
        this.loading$.next(true);
        if (res?.data?.global_details) {
          const globalDetail = res?.data?.global_details;
          if (globalDetail && globalDetail[0]?.global_detail?.name === 'ManageExtendedFiled') {
            this.extendFields = res.data.global_details[0].global_detail.extended_fields.extendArray || [];
            this.globalDetailId = res.data.global_details[0].global_detail.id;
            this.adminService.setExtendedFiled(res?.data?.global_details[0]?.global_detail);
            if (this.extendFields) {
              this.prepareData();
            }
          }
        }
      }, () => this.loading$.next(false))
    )
  }

  updateValue(index: number, index2: number, index3: number, event: any,): void {
    try {
      if (!this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data) {
        this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data = [];
        this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data.push({ id: this.projectId, value: event.target.value || '' })
      }
      else {
        const findIndex = this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data.findIndex(item => item.id === this.projectId);
        if (findIndex === -1) {
          this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data.push({ id: this.projectId as number, value: event.target.value || '' });
        } else {
          this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data[findIndex].value = event.target.value || '';
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  searchValue(index: number, index2: number, index3: number): string {
    if (this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data) {
      const findIndex = this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data.findIndex(item => item.id === this.projectId);
      if (findIndex !== -1 && this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data[findIndex].value) {
        return this.extendFields[index].jsonData.extendedFieldsConfig[index2].fields[index3].data[findIndex].value || '';
      }
    }
    return '';
  }

  updateExtendedFiled(processData: any): void {
    this.isSubmitting = true;
    this.subscriptionManager.add(this.adminService.updateExtendedFiled(processData, this.adminService.extended_Filed.id).subscribe(res => {
      this.isSubmitting = false;
      if (this.adminService.extended_Filed.id) {
        // this.onSuccess(res.data, 'Extended-Filed updated successfully');
      } else {
        // this.onSuccess(res.data, 'Extended-Filed created successfully');
      }
      this.onClose();
    }, (error) => { }));
  }

  // save(): void {
  //   if (this.extendFieldsObj) {

  //   }
  // }

  onClose(): void {
    this.closeSidebarEvent.next(true);
  }

  // prepareData(): void {
  //   const prepareKey = this.extractNames(this.extendFields, this.componentType);
  //   const prepareObj = {};
  //   for (const key of prepareKey) {
  //     const fullKey = `ext_${key?.replace(/\s/g, "")?.trim()}`
  //     if (key) {
  //       prepareObj[fullKey] = this.extendFieldsObj?.hasOwnProperty(fullKey) ? this.extendFieldsObj[fullKey] : ''
  //     }
  //   }
  //   this.extendFieldsObj = { ...this.extendFieldsObj, ...prepareObj }
  // }
  prepareData(): void {
    const prepareKey = this.extractNames(this.extendFields, this.componentType);
    const prepareObj = {};
    for (const key of prepareKey) {
      const fullKey = `ext_${key?.toLowerCase()?.replace(/\s/g, "_")?.trim()}`
      if (key) {
        prepareObj[fullKey] = this.extendFieldsObj?.hasOwnProperty(fullKey) ? this.extendFieldsObj[fullKey] : ''
      }
    }
    this.extendFieldsObj = { ...this.extendFieldsObj, ...prepareObj }
  }
  extractNames(data: any, componentName: string): string[] {
    return data?.map(item => {
      const projectConfig = item?.jsonData?.extendedFieldsConfig?.find(config => config?.component === componentName);
      return projectConfig ? projectConfig?.fields.map(field => field?.name?.replace(/\s/g, "")?.trim()) : [];
    }).flat(2);
  }

  // getValueByPartialKey(partialKey: string): string {
  //   const fullKey = `ext_${partialKey?.replace(/\s/g, "")?.trim()}`;
  //   return this.extendFieldsObj?.hasOwnProperty(fullKey) ? this.extendFieldsObj[fullKey] : "";
  // }

  // updateObjectByPartialKey(partialKey: string, value: any): void {
  //   this.dataChangeEvent.next(true);
  //   const fullKey = `ext_${partialKey?.replace(/\s/g, "")?.trim()
  //     }`;
  //   this.extendFieldsObj[fullKey] = value || '';
  //   console.log("extend", this.extendFieldsObj);
  // }
  getValueByPartialKey(partialKey: string): string {
    const fullKey = `ext_${partialKey?.toLowerCase()?.replace(/\s/g, "_")?.trim()}`;
    return this.extendFieldsObj?.hasOwnProperty(fullKey) ? this.extendFieldsObj[fullKey] : "";
  }

  updateObjectByPartialKey(partialKey: string, value: any): void {
    this.dataChangeEvent.next(true);
    const fullKey = `ext_${partialKey?.toLowerCase()?.replace(/\s/g, "_")?.trim()
      }`;
    this.extendFieldsObj[fullKey] = value || '';

  }

  checkShowORHide(filedName : string) : boolean{
    return this.filedName == '' ?  true : this.filedName===filedName? true : false
  }


}
