import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { ApiUrl } from '@shared/constants';
import { OGantryHttpResponse } from '@shared/models';
import { ProjectList, Projections, Project, Employees, AddExpense, Position, PositionList, DailyExpenseTypeList, DailyExpenses, MonthlyExpenses, MonthlyExpense, DailyExpense, ProjectDailyExpense, ProjectDailyExpenses, ProjectMonthlyExpense, ProjectMonthlyExpenses, MonthlyExpenseTypeList, BillingType, Validation, FixedBidPlugList, FixedBidPlug, EffectiveDates, ISavedFilter, ProjectStatusResponse, ProjectProjections } from './project.model';
import { Employee } from '@entities/administration/administration.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  recalculatingTheRevenue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showNewTags = new Subject<boolean>();

  constructor(private readonly http: HttpClient) { }

  setRecalculatingTheRevenueSubject(recalculateFlag) {
    this.recalculatingTheRevenue$.next(recalculateFlag);
  }
  getRecalculatingTheRevenueSubject(): Observable<boolean> {
    return this.recalculatingTheRevenue$.asObservable()
  }

  getProjectData(queryFilter: any): Observable<HttpResponse<ProjectList>> {
    return this.http.get<ProjectList>(ApiUrl.project, { params: queryFilter, observe: 'response' });
  }

  // used to get the list of projects for the specified client in the query filter
  getProjectDataWithClientName(queryFilter: any): Observable<HttpResponse<ProjectList>> {
    return this.http.get<ProjectList>(ApiUrl.project, { params: queryFilter, observe: 'response' });
  }

  getPosition(positionId: number): Observable<OGantryHttpResponse<Projections>> {
    return this.http.get<OGantryHttpResponse<Projections>>(`${ApiUrl.position}/${positionId}`);
  }

  createProjectSetup(projectSetup: Project): Observable<OGantryHttpResponse<Project>> {
    return this.http.post<OGantryHttpResponse<Project>>(ApiUrl.project, { project: projectSetup });
  }

  editProjectSetup(projectSetup: Project, projectId: number): Observable<OGantryHttpResponse<Project>> {
    return this.http.put<OGantryHttpResponse<Project>>(`${ApiUrl.project}/${projectId}`, { project: projectSetup },);
  }

  deleteProject(id: number) {
    return this.http.delete(`${ApiUrl.project}/${id}`);
  }

  getProjections(projectId: number, params): Observable<HttpResponse<OGantryHttpResponse<ProjectProjections>>> {
    return this.http.get<OGantryHttpResponse<ProjectProjections>>(`${ApiUrl.project}/${projectId}/projection`, { params: params, observe: "response" });
  }

  getProjectsProjections(queryFilter): Observable<OGantryHttpResponse<Projections>> {
    return this.http.get<OGantryHttpResponse<Projections>>(ApiUrl.projectWithProjection, { params: queryFilter });
  }

  deletePosition(id: number) {
    return this.http.delete(`${ApiUrl.position}/${id}`);
  }

  getEmployeeList(queryFilter): Observable<OGantryHttpResponse<Employees>> {
    return this.http.get<OGantryHttpResponse<Employees>>(`${ApiUrl.employee}/lookup`, { params: queryFilter });
  }
  getEmployeeDetail(id): Observable<OGantryHttpResponse<Employee>> {
    return this.http.get<OGantryHttpResponse<Employee>>(`${ApiUrl.employee}/${id}`);
  }

  createNewPosition(positionSetup: Position): Observable<OGantryHttpResponse<Position>> {
    return this.http.post<OGantryHttpResponse<Position>>(ApiUrl.position, { position: positionSetup });
  }

  getPositionList(queryFilter: any): Observable<OGantryHttpResponse<PositionList>> {
    return this.http.get<OGantryHttpResponse<PositionList>>(ApiUrl.openPositionReport, { params: queryFilter });
  }

  getDailyExpenseType(): Observable<OGantryHttpResponse<DailyExpenseTypeList>> {
    return this.http.get<OGantryHttpResponse<DailyExpenseTypeList>>(`${ApiUrl.dailyExpenseType}`);
  }

  getMonthlyExpenseType(): Observable<OGantryHttpResponse<MonthlyExpenseTypeList>> {
    return this.http.get<OGantryHttpResponse<MonthlyExpenseTypeList>>(`${ApiUrl.monthlyExpenseType}`);
  }

  addDailyExpense(addExpense: AddExpense): Observable<OGantryHttpResponse<DailyExpense>> {
    return this.http.post<OGantryHttpResponse<DailyExpense>>(`${ApiUrl.dailyExpenses}/position`, { position_daily_expense: addExpense });
  }

  addMonthlyExpense(addExpense: AddExpense): Observable<OGantryHttpResponse<MonthlyExpense>> {
    return this.http.post<OGantryHttpResponse<MonthlyExpense>>(`${ApiUrl.monthlyExpenses}/position`, { position_monthly_expense: addExpense });
  }

  deletePositionDailyExpense(id: number) {
    return this.http.delete(`${ApiUrl.dailyExpenses}/${id}/position`);
  }

  deletePositionMonthlyExpense(id: number) {
    return this.http.delete(`${ApiUrl.monthlyExpenses}/${id}/position`);
  }

  deleteProjectDailyExpense(id: number) {
    return this.http.delete(`${ApiUrl.dailyExpenses}/${id}/project`);
  }

  deleteProjectMonthlyExpense(id: number) {
    return this.http.delete(`${ApiUrl.monthlyExpenses}/${id}/project`);
  }

  invalidateProject(projectId: number): Observable<OGantryHttpResponse<Project>> {
    return this.http.put<OGantryHttpResponse<Project>>(`${ApiUrl.project}/${projectId}/invalidate`, {});
  }

  validateProject(projectId: number): Observable<OGantryHttpResponse<Project>> {
    return this.http.put<OGantryHttpResponse<Project>>(`${ApiUrl.project}/${projectId}/validate`, {});
  }
  asyncValidateProject(projectId: number): Observable<OGantryHttpResponse<Project>> {
    return this.http.put<OGantryHttpResponse<Project>>(`${ApiUrl.project}/${projectId}/validate_async`, {});
  }

  updatePosition(positionObject): Observable<OGantryHttpResponse<Position>> {
    return this.http.put<OGantryHttpResponse<Position>>(`${ApiUrl.position}/${positionObject.id}`, { position: positionObject });
  }

  getDailyExpenses(id: number): Observable<OGantryHttpResponse<DailyExpenses>> {
    return this.http.get<OGantryHttpResponse<DailyExpenses>>(`${ApiUrl.dailyExpenses}/position?position_ids=${id}`);
  }

  getMonthlyExpenses(id: number): Observable<OGantryHttpResponse<MonthlyExpenses>> {
    return this.http.get<OGantryHttpResponse<MonthlyExpenses>>(`${ApiUrl.monthlyExpenses}/position?position_ids=${id}`);
  }

  updateDailyExpense(id, addExpense: AddExpense): Observable<OGantryHttpResponse<DailyExpense>> {
    return this.http.put<OGantryHttpResponse<DailyExpense>>(`${ApiUrl.dailyExpenses}/${id}/position`, { position_daily_expense: addExpense });
  }

  updateMonthlyExpense(id, addExpense: AddExpense): Observable<OGantryHttpResponse<MonthlyExpenses>> {
    return this.http.put<OGantryHttpResponse<MonthlyExpenses>>(`${ApiUrl.monthlyExpenses}/${id}/position`, { position_monthly_expense: addExpense });
  }


  getProject(projectId: number): Observable<OGantryHttpResponse<Project>> {
    return this.http.get<OGantryHttpResponse<Project>>(`${ApiUrl.project}/${projectId}`);
  }

  getFixedBidPlugs(queryParams): Observable<OGantryHttpResponse<FixedBidPlugList>> {
    return this.http.get<OGantryHttpResponse<FixedBidPlugList>>(`${ApiUrl.fixedBidPlug}`, { params: queryParams });
  }

  addFixedBidPlug(fixedBidObj): Observable<OGantryHttpResponse<FixedBidPlug>> {
    return this.http.post<OGantryHttpResponse<FixedBidPlug>>(`${ApiUrl.fixedBidPlug}`, { fixed_bid_plug: fixedBidObj });
  }

  updateFixedBidPlug(fixedBidObj): Observable<OGantryHttpResponse<FixedBidPlug>> {
    return this.http.put<OGantryHttpResponse<FixedBidPlug>>(`${ApiUrl.fixedBidPlug}/${fixedBidObj.id}`, { fixed_bid_plug: fixedBidObj });
  }

  getRetainerPlugs(queryParams): Observable<OGantryHttpResponse<FixedBidPlugList>> {
    return this.http.get<OGantryHttpResponse<FixedBidPlugList>>(`${ApiUrl.retainerplugs}`, { params: queryParams });
  }

  updateRetainerPlugs(retainerObj): Observable<OGantryHttpResponse<FixedBidPlug>> {
    return this.http.put<OGantryHttpResponse<FixedBidPlug>>(`${ApiUrl.retainerplugs}/${retainerObj.id}`, { retainer_plug: retainerObj });
  }

  deleteFixedBidPlug(id) {
    return this.http.delete(`${ApiUrl.fixedBidPlug}/${id}`);
  }

  addProjectDailyExpense(addExpense: AddExpense): Observable<OGantryHttpResponse<ProjectDailyExpense>> {
    return this.http.post<OGantryHttpResponse<ProjectDailyExpense>>(`${ApiUrl.dailyExpenses}/project`, { project_daily_expense: addExpense });
  }

  addProjectMonthlyExpense(addExpense: AddExpense): Observable<OGantryHttpResponse<ProjectMonthlyExpense>> {
    return this.http.post<OGantryHttpResponse<ProjectMonthlyExpense>>(`${ApiUrl.monthlyExpenses}/project`, { project_monthly_expense: addExpense });
  }

  updateProjectDailyExpense(id, addExpense: AddExpense): Observable<OGantryHttpResponse<DailyExpense>> {
    return this.http.put<OGantryHttpResponse<DailyExpense>>(`${ApiUrl.dailyExpenses}/${id}/project`, { project_daily_expense: addExpense });
  }

  updateProjectMonthlyExpense(id, addExpense: AddExpense): Observable<OGantryHttpResponse<MonthlyExpenses>> {
    return this.http.put<OGantryHttpResponse<MonthlyExpenses>>(`${ApiUrl.monthlyExpenses}/${id}/project`, { project_monthly_expense: addExpense });
  }

  getProjectDailyExpenses(id: number): Observable<OGantryHttpResponse<ProjectDailyExpenses>> {
    return this.http.get<OGantryHttpResponse<ProjectDailyExpenses>>(`${ApiUrl.dailyExpenses}/project?project_id=${id}`);
  }

  getProjectMonthlyExpenses(id: number): Observable<OGantryHttpResponse<ProjectMonthlyExpenses>> {
    return this.http.get<OGantryHttpResponse<ProjectMonthlyExpenses>>(`${ApiUrl.monthlyExpenses}/project?project_id=${id}`);
  }

  getProjectStatus(): Observable<OGantryHttpResponse<ProjectStatusResponse>> {
    return this.http.get<OGantryHttpResponse<ProjectStatusResponse>>(`${ApiUrl.projectStatus}`);
  }

  getBillingType(): Observable<BillingType> {
    return this.http.get<BillingType>(ApiUrl.billingType);
  }

  getProjectsProfitLoss(queryFilter): Observable<HttpResponse<Projections>> {
    return this.http.get<Projections>(ApiUrl.projectWithProfitLoss, { params: queryFilter, observe: 'response' });
  }

  getCompareProjections(queryFilter): Observable<HttpResponse<Projections>> {
    return this.http.get<Projections>(ApiUrl.compareProjections, { params: queryFilter, observe: 'response' });
  }

  getValidationStatus(projectId: number): Observable<OGantryHttpResponse<Validation>> {
    return this.http.get<OGantryHttpResponse<Validation>>(`${ApiUrl.project}/${projectId}${ApiUrl.validationStatus}`);
  }

  getProjectEffectiveDates(queryFilter?): Observable<EffectiveDates> {
    if (queryFilter) {
      return this.http.get<EffectiveDates>(`${ApiUrl.project}/eff_dates`, { params: queryFilter });
    }
    return this.http.get<EffectiveDates>(`${ApiUrl.project}/eff_dates`);
  }

  getTheFilterById(filterId: number): Observable<ISavedFilter> {
    return this.http.get<ISavedFilter>(`${ApiUrl.saveFilter}/${filterId}`);
  }

}
